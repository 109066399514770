import { FC } from 'react';

import Column from 'bloko/blocks/column';

import ApplicantSideBar from 'lux/components/ApplicantSideBar';
import PageLayout, { SideBarLayout } from 'lux/components/PageLayout';
import { useSelector } from 'lux/modules/useSelector';

import Alphabet from 'lux/pages/EmployersList/components/Alphabet';
import Companies from 'lux/pages/EmployersList/components/Companies';
import EmployerRegistrationButton from 'lux/pages/EmployersList/components/EmployerRegistrationButton';
import Filters from 'lux/pages/EmployersList/components/Filters';

const EmployersList: FC = () => {
    const { title, description } = useSelector((state) => state.pageMetaData);

    return (
        <PageLayout title={title} description={description} layout={SideBarLayout} sidebar={ApplicantSideBar}>
            <Column m="8" l="11">
                <EmployerRegistrationButton />
                <Filters />
                <Alphabet />
                <Companies />
            </Column>
        </PageLayout>
    );
};

export default EmployersList;
