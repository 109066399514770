import { useState, useCallback, ChangeEvent, memo } from 'react';

import Checkbox from 'bloko/blocks/checkbox';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SUPERNOVA_SEARCH_FORM_ID } from 'lux/components/SupernovaSearch';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import AreaSelection from 'lux/pages/EmployersList/components/AreaSelection';

import styles from './filters.less';

const TrlKeys = {
    search: 'button.search',
    vacanciesRequired: 'checkbox.vacanciesRequired',
    agencies: 'employersList.recruitment.agencies',
    selectRegion: 'vacancySearch.selectregionfromlist',
    treeselectorSubmit: 'treeselector.save',
    treeselectorCancel: 'treeselector.cancel',
    treeselectorSearch: 'treeselector.quicksearch',
    treeselectorNotFound: 'treeselector.notFound',
};

const Filters: TranslatedComponent = ({ trls }) => {
    const { areaId, vacanciesNotRequired } = useSelector(({ router }) => router.location.query);

    const [notRequiredCheckbox, setNotRequiredCheckbox] = useState(vacanciesNotRequired?.toLowerCase() === 'true');

    const handleCheckboxChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setNotRequiredCheckbox(event.target.checked);
        },
        [setNotRequiredCheckbox]
    );

    return (
        <>
            <div className={styles.flexAlignCenter}>
                <AreaSelection areaId={areaId} />
                <a href="/agencies" className={styles.agenciesLink}>
                    {trls[TrlKeys.agencies]}
                </a>
            </div>
            <Checkbox
                form={SUPERNOVA_SEARCH_FORM_ID}
                value={notRequiredCheckbox ? 'True' : 'False'}
                checked={notRequiredCheckbox}
                type="checkbox"
                onChange={handleCheckboxChange}
                name="vacanciesNotRequired"
            >
                {trls[TrlKeys.vacanciesRequired]}
            </Checkbox>
            <VSpacing base={6} />
        </>
    );
};

export default memo(translation(Filters));
