import { memo } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind, ButtonScale } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    title: 'title.newEmployer',
};

const EmployerRegistrationButton: TranslatedComponent = ({ trls }) => {
    const userType = useSelector(({ userType }) => userType);
    const backUrl = useSelector((state) => state.request.url);

    return userType === UserType.Anonymous ? (
        <Gap bottom>
            <Button
                Element={Link}
                scale={ButtonScale.Small}
                kind={ButtonKind.Success}
                to={`/auth/employer/?backurl=${encodeURIComponent(backUrl)}`}
            >
                {trls[TrlKeys.title]}
            </Button>
        </Gap>
    ) : null;
};

export default memo(translation(EmployerRegistrationButton));
