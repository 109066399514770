import { memo } from 'react';

import Link from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import translation from 'lux/components/translation';
import useUrl from 'lux/hooks/useUrl';
import { useSelector } from 'lux/modules/useSelector';

import styles from './alphabet.less';

const RUS_ALLOWED_LETTERS = 'АБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЭЮЯ';
const ENG_ALLOWER_LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

function getLetterUrl(url: string, letter: string) {
    const parsedUrl = urlParser(url);
    parsedUrl.params.letter = letter;
    delete parsedUrl.params.page;
    return parsedUrl.href;
}

const TrlKeys = {
    numerics: 'employersList.find.by.employer.name.numerics',
    all: 'employersList.find.by.employer.name.all',
};

const Alphabet: TranslatedComponent = ({ trls }) => {
    const { letter: queryLetter } = useSelector(({ router }) => router.location.query);
    const url = useUrl();

    const renderLetter = (letter: string, text?: string) => (
        <li className={styles.item} key={letter}>
            {letter === decodeURIComponent(queryLetter || '') ? (
                <strong data-qa="alfabeta-list-strong">{text || letter}</strong>
            ) : (
                <Link data-qa={`alfabeta-list-link-${text || letter}`} disableVisited href={getLetterUrl(url, letter)}>
                    {text || letter}
                </Link>
            )}
        </li>
    );

    return (
        <div>
            <ul className={styles.list}>{RUS_ALLOWED_LETTERS.split('').map((letter) => renderLetter(letter))}</ul>
            <ul className={styles.list}>{ENG_ALLOWER_LETTERS.split('').map((letter) => renderLetter(letter))}</ul>
            <ul>
                {renderLetter('#', trls[TrlKeys.numerics])}
                {renderLetter('', trls[TrlKeys.all])}
            </ul>
        </div>
    );
};

export default memo(translation(Alphabet));
